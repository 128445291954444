
import { defineComponent } from 'vue';
import { RouteLocation } from 'vue-router';
import { mapGetters, mapActions } from 'vuex';

import AuthService from '@/services/AuthService';
import OnboardingRedirectService, {
  isInAnyOnboardingRoutes
} from '@/services/OnboardingRedirectService';
import StorageUtils from '@livongo/utilities/system/storage';

import { FeatureFlags } from '@/types/FeatureFlagTypes';
import { LoginWithLivongoErrors } from '@/types/UserTypes';
import OneApp from '@/components/app/mixins/OneApp';

import JoinConferenceButton from '@/components/conference/JoinConferenceButton.vue';
import NavigationSide from '@/components/navigation/NavigationSide.vue';
import NavigationTop from '@/components/navigation/NavigationTop.vue';
import BaseDrawerModal from '@/components/base/BaseDrawerModal.vue';
import Notifications from '@/components/common/Notifications.vue';
import SessionTimer from '@/components/SessionTimer.vue';
import NewFeatures from '@/views/NewFeatures.vue';
import SignUpError from '@/views/SignUpError.vue';
import MHFooter from '@/components/Footer.vue';
import Guide from '@/views/Guide/Guide.vue';
import store from '@/store';

const SIGN_UP_ROUTE_NAMES = ['AccountSetup', 'GdprTerms', 'SignUp'];
const ONBOARDING_EXEMPT_URLS = ['/logout', '/destination-chooser'];

export default defineComponent({
  name: 'App',
  inject: [
    '$analyticsRegisterGlobalFields',
    '$analyticsTrack',
    '$analyticsReset'
  ],
  components: {
    Guide,
    MHFooter,
    NewFeatures,
    SignUpError,
    SessionTimer,
    NavigationTop,
    Notifications,
    NavigationSide,
    BaseDrawerModal,
    JoinConferenceButton
  },
  mixins: [OneApp],
  data() {
    return {
      isOnboarding: false,
      incorrectlyInSignUp: false,
      isAppBootstrapLoading: true,
      isAuthForLivongoTokensLoading: false,
      removeCookiesInterval: null
    };
  },
  computed: {
    showNav(): boolean {
      return (
        this.isAuthenticated &&
        !this.isOnboarding &&
        !this.isWebviewRoute &&
        !this.isDeepLinkRoute &&
        !this.inDestinationChooser &&
        !this.inUpgradeRegistration &&
        !this.isAgingUpRoute &&
        !AuthService.getClinicalPortalUser() &&
        // Hide navigation based on route meta value: hideNav
        !this.$route.meta?.hideNav
      );
    },
    isAuthenticatedCoreRoute(): boolean {
      return this.isAuthenticated && !this.inLogin && !this.isWebviewRoute;
    },
    isDeepLinkRoute(): boolean {
      return this.$route.name === 'DeepLink';
    },
    isWebviewRoute(): boolean {
      // Dedicated webview & mobile only route base
      // See routes in src/router/webviewRoutes.ts
      return this.$route.path.includes('/web-view');
    },
    isAgingUpRoute(): boolean {
      return this.$route.name === 'AgingUp';
    },
    inDestinationChooser(): boolean {
      return (
        this.$route.path.includes('/destination-chooser') ||
        this.$route.path.includes('/mfa-verify')
      );
    },
    inLogin(): boolean {
      return this.$route.path.includes('/login');
    },
    inUpgradeRegistration(): boolean {
      return this.$route.path.includes('/upgrade-registration');
    },
    showNewFeatures(): boolean {
      return this.drawerComponent === 'NewFeatures';
    },
    showGuide(): boolean {
      return this.drawerComponent === 'Guide';
    },
    hasGuide(): boolean {
      return !!this.user?.coachingAssignment;
    },
    wrapperSize(): string {
      return this.$route.meta?.baseWrapperSize ?? 'md';
    },
    keepAliveComponents(): string[] {
      const keepAliveComponents = [];

      if (this.meetingOn) {
        keepAliveComponents.push('Conference');
      }

      return keepAliveComponents;
    },
    mobileWebViewClass(): Record<string, boolean> {
      return {
        'is-mobile-web-view': this.isMobileWebView
      };
    },

    ...mapGetters([
      'user',
      'hasAgedUp',
      'meetingOn',
      'livongoAuthStatus',
      'scrollDisabled',
      'isAuthenticated',
      'drawerComponent',
      'onboardingStatus',
      'hasFeatureEnabled',
      'requiresLivongoReg',
      'mobileScrollDisabled',
      'isMobileWebView'
    ])
  },
  watch: {
    $route(to) {
      this.unblockScroll();
      this.setPageTitleFromRoute(to);
      this.checkIncorrectlyInSignUp();
      this.isOnboarding = isInAnyOnboardingRoutes(to);
    },
    isAuthenticated() {
      if (AuthService.getAuthToken()) {
        this.globalGetUser().then(() => {
          this.setAppLocaleFromUser();
        });
      }
    },
    user: {
      deep: true,
      handler() {
        const currentPath = this.$route.path.toLowerCase();
        const inOnboardingOrReAssessment =
          currentPath &&
          (currentPath.includes('assessment') ||
            currentPath.includes('onboarding'));
        this.setAppLocaleFromUser();

        // Avoid redirects for:
        // - webViews ( one app webViews excluded )
        // - exempt urls like '/destination-chooser'
        // - users already in onboarding or reassessment
        if (
          (this.isMobileWebView && !this.isOneApp) ||
          ONBOARDING_EXEMPT_URLS.includes(currentPath) ||
          inOnboardingOrReAssessment
        ) {
          return;
        }

        const onboardingRedirectRoute = OnboardingRedirectService(this.$route);
        if (onboardingRedirectRoute) {
          // This was added for cases where re-auth & request retries occurred,
          // Then the user was not redirected to complete onboarding
          this.$router.push({
            path: onboardingRedirectRoute
          });
        }
      }
    },
    scrollDisabled(isDisabled) {
      // Used to adjust scroll behavior on mobile menu open
      if (isDisabled) {
        document.documentElement.classList.add('disable-scroll');
      } else {
        document.documentElement.classList.remove('disable-scroll');
      }
    },
    mobileScrollDisabled(isMobileScrollDisabled) {
      // Used to adjust scroll behavior
      if (isMobileScrollDisabled) {
        document.documentElement.classList.add('disable-mobile-scroll');
      } else {
        document.documentElement.classList.remove('disable-mobile-scroll');
      }
    },
    hasGuide: {
      immediate: true,
      handler(value) {
        if (value) {
          this.startMsgPolling();
        } else {
          this.stopMsgPolling();
        }
      }
    },
    livongoAuthStatus: {
      deep: true,
      handler(value) {
        if (!value || !value.errorCode) {
          return;
        }

        // Direct users with malformed livongo token
        if (value.errorCode === LoginWithLivongoErrors.MISSING_BH_SCOPE) {
          this.$router.push({ name: 'OnboardingIneligible' });
        }
      }
    }
  },
  created() {
    // Set the initial global fields
    // Global fields also updated when the user is loaded in store
    this.$analyticsRegisterGlobalFields();
    if (this.isOneApp && !this.isMobileWebView) {
      AuthService.clearAuthToken();
      AuthService.clearReAuthToken();
      store.commit('setAuthenticated', false);
    }
    this.fetchGlobalWebBootstrap();
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.authForLivongoTokens();
      this.setPageTitleFromRoute();
      this.followStoredRedirectUrl();
      this.checkIncorrectlyInSignUp();
      this.initRemoveUnauthorizedCookies();

      if (
        this.hasFeatureEnabled(FeatureFlags.CLINICAL_COACHING) &&
        AuthService.getClinicalPortalUser()
      ) {
        this.$router.push({ name: 'Conference' });
      } else {
        this.isOnboarding = isInAnyOnboardingRoutes(this.$route);
      }
    },
    initRemoveUnauthorizedCookies() {
      StorageUtils.deleteUnAuthorizedCookie();

      clearInterval(this.removeCookiesInterval);
      this.removeCookiesInterval = setInterval(() => {
        StorageUtils.deleteUnAuthorizedCookie();
      }, 60000);
    },
    authForLivongoTokens() {
      const livongoAuthToken = AuthService.getLivongoAuthToken();
      const livongoReAuthToken = AuthService.getLivongoReAuthToken();
      const hasLivongoTokens = livongoAuthToken && livongoReAuthToken;

      // Skip if not applicable
      if (!hasLivongoTokens) {
        return;
      }

      this.isAuthForLivongoTokensLoading = true;
      this.loginUserWithLivongoToken({
        authToken: livongoAuthToken,
        reauthToken: livongoReAuthToken
      })
        .then(() => {
          // isAuthenticated flag is set
          this.$analyticsTrack('MH.Login.Succeed');
        })
        .then(() => {
          return this.updateLanguagePreference();
        })
        .finally(() => {
          this.isAuthForLivongoTokensLoading = false;
        });
    },
    setAppLocaleFromUser() {
      this.$root.$i18n.locale = this.user.language
        ? this.user.language.split('-')[0]
        : this.$root.$i18n.locale;
    },
    setPageTitleFromRoute(to?: RouteLocation) {
      const titleTranslationKey = to ? to?.name : this.$route?.name || '';
      const translationKey = `pageTitles.${titleTranslationKey}`;
      const brandName = this.$t('brandName') as string;
      const pageTitlePrefix = this.$t(translationKey) as string;

      // i18n service returns the key if value not found
      if (titleTranslationKey && translationKey === pageTitlePrefix) {
        console.warn('Page title translation not provided: ', translationKey);
        // Fallback brand name only
        document.title = `${brandName}`;
      } else if (pageTitlePrefix) {
        // Set page title
        document.title = `${pageTitlePrefix} - ${brandName}`;
      } else {
        // Fallback brand name only
        document.title = `${brandName}`;
      }
    },
    fetchGlobalWebBootstrap(): void {
      this.fetchApiConfig();
      return this.globalGetUser()
        .then(() => {
          if (this.isMobileWebView) {
            return this.updateLanguagePreference();
          }
        })
        .then(
          () => {
            this.setAppLocaleFromUser();
            // Avoid any redirects for webview context
            if (this.isMobileWebView) {
              this.isAppBootstrapLoading = false;
              return;
            }

            // Track a user session starting onboarding
            if (this.isOnboarding) {
              this.$analyticsTrack('MH.Onboarding.Start', {
                onboardingStatus: this.onboardingStatus
              });
            }

            const redirectRoute =
              OnboardingRedirectService(this.$router.currentRoute) ||
              this.$route.query.redirect;

            if (
              this.requiresLivongoReg &&
              AuthService.hasAuthToken() &&
              this.$router.currentRoute.path !== '/logout'
            ) {
              this.$router.push('/upgrade-registration');
            } else if (redirectRoute && this.$route.path !== redirectRoute) {
              this.$router.push({
                path: decodeURIComponent(redirectRoute.toString())
              });
            } else if (this.hasAgedUp) {
              this.$router.push({ name: 'AgingUp' });
            } else if (this.$route.path === '/login') {
              this.$router.push({ name: 'Home' });
            }
          },
          () => {
            // Empty callback needed to avoid unhandled exception
          }
        )
        .finally(() => {
          this.isAppBootstrapLoading = false;
        });
    },
    globalGetUser(): Promise<void> {
      const token = AuthService.getAuthToken();
      const accessToken = AuthService.getLivongoAuthToken();
      const sameToken = token === accessToken;

      if ((token && !accessToken) || (token && accessToken && sameToken)) {
        return this.getUser().then(
          () => {
            // Ignore success & avoid unhandled exception
          },
          () => {
            // Ignore failure & avoid unhandled exception
          }
        );
      } else {
        return Promise.reject();
      }
    },
    unblockScroll() {
      document.documentElement.classList.remove('disable-scroll');
      document.documentElement.classList.remove('disable-mobile-scroll');
    },
    checkIncorrectlyInSignUp() {
      // Can this become computed property?
      this.incorrectlyInSignUp =
        this.isAuthenticated &&
        SIGN_UP_ROUTE_NAMES.includes(this.$route.name || '');
    },
    followStoredRedirectUrl() {
      // For One App Mobile Web-view (mobile doesn't have localStorage)
      if (!window.localStorage) {
        return;
      }
      const redirectUrl = window.localStorage.getItem('redirectUrl');

      if (redirectUrl) {
        window.localStorage.removeItem('redirectUrl');
        this.$router.push({ path: redirectUrl });
      }
    },
    ...mapActions([
      'getUser',
      'stopMsgPolling',
      'fetchApiConfig',
      'startMsgPolling',
      'loginUserWithLivongoToken'
    ])
  }
});
